"use client";
import { useLayoutEffect, useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import { useTheme } from "@mui/material";

import { useStore, observer } from "../../service/mobx";

function HooksAbout() {
  const { analytics, bottomNav, user } = useStore();
  const theme = useTheme();

  useEffect(() => {
    analytics.set.context("LandingPage", "View");

    return analytics.set.context;
  }, [analytics]);

  useLayoutEffect(() => {
    // update styling
    const rail = document.getElementById("rail");
    const bottomNav = document.getElementById("bottomNav");

    if (bottomNav) {
      bottomNav.style.display = "none";
    }
    if (rail) {
      rail.style.display = "none";
    }

    document.body.style.padding = 0;
    document.body.style.background = theme.palette.dark.surface.color;
    document.body.style.transition = "all 1s cubic-bezier(0.4, 0, 0.2, 1) 0ms";

    return () => {
      // undo styling
      document.body.style.removeProperty("padding");
      document.body.style.removeProperty("background");
      document.body.style.removeProperty("transition");
      //
      document.getElementById("rail")?.style.removeProperty("display");
      document.getElementById("bottomNav")?.style.removeProperty("display");
    };
  }, [theme, user.loaded, bottomNav.loaded]);
}

export default observer(HooksAbout);

export function useLandingPage(route) {
  const [marketingPageNotSeen, setNotSeen] = useState(true);
  const { user, utilities } = useStore();
  const router = useRouter();
  const shouldLoad =
    marketingPageNotSeen &&
    user.loaded &&
    user.isAnonymous &&
    user.firstExperience &&
    route === "(discover)";

  useEffect(() => {
    utilities.cache
      .get("marketing-page")
      .then(value => setNotSeen(value === null));
  }, [utilities]);

  useEffect(() => {
    if (shouldLoad) {
      router.push("/about");
      setNotSeen(false);
      utilities.cache.set("marketing-page", 1);
    }
  }, [utilities, shouldLoad, router]);
}
